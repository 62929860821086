var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-app-bar',{attrs:{"app":"","color":"white","clipped-right":"","clipped-left":""}},[_c('v-responsive',{attrs:{"max-width":"260"}},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.loadPatient.apply(null, arguments)}}},[_c('span',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['ctrl', 'k']),expression:"['ctrl', 'k']"}],on:{"shortkey":function($event){return _vm.focusRecherche()}}}),_c('autocomplete-patient',{ref:"autocomplete",attrs:{"patientsArray":_vm.patientsArray,"searchHistory":_vm.searchHistory,"label":'Recherche [Ctrl+K]'},on:{"patient-selected":_vm.onPatientSelected}})],1)],1),_c('v-tabs',{staticClass:"ml-n9",attrs:{"centered":"","color":"grey darken-1"},model:{value:(_vm.active_tab),callback:function ($$v) {_vm.active_tab=$$v},expression:"active_tab"}},[_c('v-tab',{attrs:{"to":"/facturation"}},[_vm._v("Facturation")]),_c('v-tab',{attrs:{"to":"/mckesson/produit"}},[_vm._v("McKesson")]),_c('v-tab',{attrs:{"to":"/recherche_produit"}},[_vm._v("Produit")]),_c('v-tab',{attrs:{"to":"/lots"}},[_vm._v("Lots")]),(
        typeof this.$route.params.patient_id != 'undefined'
      )?_c('v-tab',{attrs:{"to":_vm.registreLink}},[_vm._v("Registre de changements")]):_vm._e(),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"align-self-center mr-4",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" Autres "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',{staticClass:"grey lighten-3"},[_c('v-list-item',{attrs:{"to":"/liste_patient"}},[_vm._v(" Liste patient ")]),_c('v-list-item',{attrs:{"to":"/rapport/profit"}},[_vm._v(" Profits en erreur ")]),_c('v-list-item',{attrs:{"to":"/rapport/clover"}},[_vm._v(" Rapport des transactions Clover ")]),_c('v-list-item',{attrs:{"to":"/renouvellement/verification"}},[_vm._v(" Vérification des renouvellements ")])],1)],1),(
        typeof this.$route.params.patient_id == 'undefined'
      )?_c('v-tab',{attrs:{"disabled":""}},[_vm._v("Registre de changements")]):_vm._e()],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.setPrinter()}}},[_c('v-icon',{class:{ 'green--text': this.isReadyToPrint, 'red--text': !this.isReadyToPrint },attrs:{"size":"32"}},[_vm._v("mdi-printer")])],1),(
        typeof _vm.decoded.succursale !== 'undefined' &&
        typeof _vm.decoded.succursale.id !== 'undefined'
      )?_c('span',{staticClass:"hidden-sm-and-down text-capitalize pr-6"},[_c('b',[_vm._v(_vm._s(_vm.decoded.succursale.id))])]):_vm._e(),(_vm.user)?_c('span',{staticClass:"hidden-sm-and-down text-capitalize"},[_vm._v(_vm._s(_vm.user.fname)+" "+_vm._s(_vm.user.lname))]):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.userLogout()}}},[_c('v-icon',{attrs:{"size":"32"}},[_vm._v("mdi-logout")])],1)],1),_c('v-main',{staticClass:"grey lighten-3"},[(!_vm.isProd)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v("Attention, ceci n'est pas la vrai version de CODEX")]):_vm._e(),(_vm.message!=null)?_c('v-alert',{attrs:{"dismissible":"","type":"success"}},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e(),_c('router-view')],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.userLoggedOut),callback:function ($$v) {_vm.userLoggedOut=$$v},expression:"userLoggedOut"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" NIP employé ")]),_c('v-card-text',[_c('v-text-field',{attrs:{"autofocus":"","outlined":"","type":"number","label":"NIP"},on:{"input":function($event){return _vm.onNipInput()}},model:{value:(_vm.nip),callback:function ($$v) {_vm.nip=$$v},expression:"nip"}}),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.logout()}}},[_vm._v(" Déconnexion succursale ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }